import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import 'firebase/firestore';
import * as firebase from 'firebase';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  title: string;
  text: string;
  err: string;
  imageFile: File;
  loading: boolean;


  constructor(private auth: AuthService, private storage: AngularFireStorage, private afs: AngularFirestore, private _snackBar: MatSnackBar) {

  }

  ngOnInit() {
  }

  onFileChange(event) {
    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.imageFile = file;
      console.log(file.name)
    }
  }

  async submit() {
    console.log(this.title, this.text);
    if (!this.title) {
      this.err = 'タイトルを入力してください';
      return;
    }
    if (!this.text) {
      this.err = '本文を入力してください';
      return;
    }
    this.loading = true;
    try {
      var inputData: any = {
        title: this.title,
        text: this.text,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      };
      const notificationKey = firebase.firestore().collection('corporateNotifications').doc().id;
      if (this.imageFile) {
        const storageRef = this.storage.ref(`corporateNotifications/${notificationKey}`);
        await storageRef.put(this.imageFile);
        const imageUri = await storageRef.getDownloadURL().toPromise();
        inputData.imageUri = imageUri;
      }
      await this.afs.doc(`corporateNotifications/${notificationKey}`).set(inputData, { merge: true });
      this.title = '';
      this.text = '';
      this.imageFile = null;
      this.loading = false;
      this._snackBar.open('通知が送信されました！', null, {duration: 3000});
    }catch(e) {
      console.error(e);
      this.loading = false;
      this._snackBar.open('通知の送信に失敗しました...', null, {duration: 3000});
    }
  }
}
